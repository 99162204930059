import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './i18n';

import '@prometeoapi/prometeo-design-system';
import './assets/css/DemoApp.css';
import '@prometeoapi/prometeo-font-files';

// Pages
const Home = lazy(() => import('./pages/Home'));
const BankInfoAccess = lazy(() => import('./pages/BankInfoAccess'));
const Identity = lazy(() => import('./pages/Identity'));
const BankAccount = lazy(() => import('./pages/BankAccount'));
const GetPaid = lazy(() => import('./pages/GetPaid'));
const Payments = lazy(() => import('./pages/Payments'));
const BAM = lazy(() => import('./pages/BAM'));
const BankAccountValidation = lazy(() => import('./pages/BankAccountValidation'));
const BankConsolidation = lazy(() => import('./pages/BankConsolidation'));
const Payouts = lazy(() => import('./pages/Payouts'));

function App() {
  return (
    <div className='App'>
      <Router>
        <Suspense fallback=''>
          <Switch>
            <Route exact path={['/', '/en']}>
              <Home />
            </Route>
            <Route exact path='/identity'>
              <Identity />
            </Route>
            <Route path='/identity/:pageNumber'>
              <Identity />
            </Route>
            <Route exact path={['/bankinfoaccess', '/en/bankinfoaccess']}>
              <BankInfoAccess />
            </Route>
            <Route path={['/bankinfoaccess/:pageNumber', '/en/bankinfoaccess/:pageNumber']}>
              <BankInfoAccess />
            </Route>
            <Route exact path='/bankaccount'>
              <BankAccount />
            </Route>
            <Route path='/bankaccount/:pageNumber'>
              <BankAccount />
            </Route>
            <Route exact path={['/getpaid', '/en/getpaid']}>
              <GetPaid />
            </Route>
            <Route path={['/getpaid/:pageNumber', '/en/getpaid/:pageNumber']}>
              <GetPaid />
            </Route>
            <Route exact path='/payments'>
              <Payments />
            </Route>
            <Route path='/payments/:pageNumber'>
              <Payments />
            </Route>
            <Route exact path='/bankaccountmanagement'>
              <BAM />
            </Route>
            <Route path='/bankaccountmanagement/:pageNumber'>
              <BAM />
            </Route>
            <Route exact path={['/bankaccountvalidation', '/en/bankaccountvalidation']}>
              <BankAccountValidation />
            </Route>
            <Route
              path={[
                '/bankaccountvalidation/:pageNumber',
                '/en/bankaccountvalidation/:pageNumber'
              ]}>
              <BankAccountValidation />
            </Route>
            <Route exact path={['/bankconsolidation', '/en/bankconsolidation']}>
              <BankConsolidation />
            </Route>
            <Route path={['/bankconsolidation/:pageNumber', '/en/bankconsolidation/:pageNumber']}>
              <BankConsolidation />
            </Route>
            <Route exact path={['/payouts', '/en/payouts']}>
              <Payouts />
            </Route>
            <Route path={['/payouts/:pageNumber', '/en/payouts/:pageNumber']}>
              <Payouts />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
